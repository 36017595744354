export const isUsingVercel = true;
export const isLocal = false;
//

export const baseurl = isLocal
  ? 'http://localhost:3000/api/'
  : isUsingVercel
    ? 'https://api.destcert.com/api/'
    : 'https://hwni3pu1y1.execute-api.us-east-2.amazonaws.com/prod/';

export const environment = {
  ACCESS_KEY:
    // 'J64bIC2bMEJp9R8bRH1XxIIeSZ1+V7z2S1PP0L5d',
    '53616c7465645f5f13d88f696384d936a4cb5a7213f7027578fa655f1557834c265865fe3d31eecd21672df60e869a1fe65b17150e88c8cd6dfb6c51ca15885b',
  ACTIVITY_TRACKER: 'testlogs/activity_tracker',
  ADDSUPPORT: 'authy/addsupport',
  ADD_ACTIVITY_TRACKER: 'authy/add_activity_tracker',
  ADD_AUTHY: 'authy/add_authy',
  ADD_FAILED_EMAIL: 'authy/add_failed_emails',
  ADD_MESSAGE: 'chatting/add',
  ADD_MESSAGE_CONFERENCE: 'meeting/save_message',
  ADD_NOTIFICATION: 'notification/add',
  ADD_ONBORDING: 'authy/add_onbording',
  ADD_SELFCOHORT_STD: 'authy/add_selfcohort_student',
  ALL_ALIASNAME: 'authy/getallalias',
  ALL_CHANNEL: 'chatting/all_channel_certiwise',
  AUTHY_SECRET: 'authy/secret',
  AUTHY_VERIFY: 'authy/verify',
  CHANNEL_USERS: 'chatting/get_channel_users',
  COHORTSCERTIFICATEEXPIREDATE: 'points/cohortsCertificateExpireDate',
  COHORT_BY_IDS: 'testlogs/cohortbyIds',
  COURSE_ALL: 'course/all',
  ClientId:
    // '6nsghkfji3fipnnsh0khjcm1tb',
    '53616c7465645f5fcaca636da6bf2610832b9b527e3fa08f89fab933af9566e5f09efd00b8f9cd304f422bbfe13618bb',
  DELETE_MESSAGE: 'chatting/delete',
  DISSCUSSION_ADD: 'disscussion/add',
  DISSCUSSION_BY: 'disscussion/by',
  DOMAIN_LIST: 'acronyms/survey_domain',
  EMOJI_UPDATE: 'chatting/update_emoji',
  ENROLL_ADD: 'enroll/add',
  FETCH_PAY: 'authy/fetch_user_payment',
  FLASHCARD_ALL: 'flashcard/all',
  FLASHCARD_GETALL_CARDS: 'flashcardapp/getallcards',
  GETREVIEWGUIDE: 'userlogs/usp_getreviewguide',
  GET_ALL_NOTIFICATION: 'notification/notification_all',
  GET_BASELINE_KA: 'points/get_baseline_ka',
  GET_EV_RECORD: 'live_events/get_event_recordings',
  GET_EXPIRYDATE: 'self_paced/get_expirydate',
  GET_MEETING: 'authy/get_meeting',
  GET_MESSAGE: 'chatting/get_message',
  GET_MESSAGE_CONFERENCE: 'meeting/get_message',
  GET_NEXT_LIVE_CALL: 'points/get_next_live_call',
  GET_NOTIFICATION_SETTING: 'notification/get_setting',
  GET_ONBORDING: 'authy/get_onbording',
  GET_PAYMENT_TRANSACTIONS: 'emailtemplate/get_payment_transactions_by_user',
  GET_POPUP_NOTIFICATION: 'notification/popup_notification',
  GET_SELF_PACED_STUDY_TIME: 'self_paced/selfpaced_times',
  GET_SELF_SCHEDULE: 'self_paced/get_self_schedule',
  GET_SINGLE_MESSAGE: 'chatting/get_single_message',
  GET_SINGLE_MESSAGE_CONFERENCE: 'meeting/get_single_message',
  GET_SURVEY_ACTIVITY: 'acronyms/get_survey',
  GET_WELCOME_LINKS: 'authy/get_welcome_links',
  KETEXAMANS_CATEX_COMPLATE: 'catexamans/catexamcomplate',
  KETEXAM_ADD_KETEXAMTEST_ANS: 'catexamans/addketexamtestans',
  KETEXAM_ANS_FILTER: 'catexamans/filtertestques',
  KETEXAM_ANS_GETDOMAIN_REPORT: 'catexamans/getdomainreportketexamtestans',
  KETEXAM_ANS_GETUSERREPORT: 'catexamans/getuserreportketexamtestAns',
  KETEXAM_TEST: 'catexamtest/',
  KEY_ID:
    // 'AKIAWXQ73NHRLQLKIY42',
    '53616c7465645f5f5a165efa7969bced05af609ea1ba6175dce7efdf45d7c46dc74f008aaa4b53f036e691be1b547fb6',
  KNOW_ASS_QUES_ADD: 'knowassqueans/add',
  KNOW_ASS_QUES_ALL: 'knowAssQues/all',
  KNOW_ASS_QUES_COURSE: 'testlogs/usp_getenrollcohorts',
  KNOW_ASS_QUES_DOMAIN_REPORT: 'knowassqueans/domain-report',
  KNOW_ASS_QUES_FILTER_QUES: 'knowassque/filter-ques',
  KNOW_ASS_REPORT: 'knowassqueans/ass-report',
  LIVEQ_ADDANS: 'liveq/addans',
  LIVEQ_LIVE_BYID: 'liveq/livebyid',
  LIVE_EVENT_BYID: 'live_events/byid',
  LIVE_LIVEBYID: 'live/livebyid',
  LOGINLOGS_ADD: 'loginlogs/add',
  MARK_AS_READ: 'chatting/mark_as_read',
  MEETING_BYID: 'meeting/byid',
  MEMBERSHIP_DETAIL: 'authy/get_membership',
  MEMBER_MATERIAL: 'self_paced/get_membership_materials',
  MIND_MAP: 'mindmap/allmaps',
  MIND_MAP_ALL: 'mindmap/allmaps',
  MIND_MAP_MEMBERSHIP: 'mindmap/allmaps_member',
  MIND_UPDATE: 'mindmap/updateviewer',
  ONETIME_MEMBERSHIP_DETAIL: 'authy/get_onetime_membership',
  OVERDUE_NOTIFICATION_STOP: 'authy/overdue_notification_stop',
  PAYMENT_TRANSACTION: 'authy/add_payment_transaction',
  POINTS_DASH_ACTIVITY: 'points/dashactivity',
  POINTS_KNOWASS_COMPLETE: 'points/knowasscomplete',
  POINTS_LBWITH_DOMAIN: 'points/lbwithdomainid',
  POINTS_LEADER_BOARD: 'points/usp_leaderborad',
  POINTS_SCHEDULES: 'points/schedules',
  POINTS_STUDENT_DASH: 'points/studentdash',
  PRACTICEANS_PRE_COMPLATE: 'practiceans/practicecomplate',
  PRACTICE_ID: 'userlogs/ptbyid',
  PRECTICEANS_ADD_PRECTICE: 'practiceans/addpracticetestans',
  PRECTICEANS_FILTERS_QUEST: 'practiceans/practice_result_pagination',
  PRECTICEANS_GETDOMAIN_REPORT: 'practiceans/getdomainreportpracticetestans',
  PRECTICEANS_GETUSERREPORT_PRE: 'practiceans/getuserreportpracticetestAns',
  PRECTICETEST: 'userlogs/ptbyid',
  QUEDISCUSION_ADD: 'quediscusion/add',
  READ_POPUP_NOTIFICATION: 'notification/markread_popup_notification',
  RESET_EXAM: 'testlogs/kareset',
  RESET_PRACTICETEST: 'testlogs/practicereset',
  REVIEW_GUIDE: 'disscussion/review_guide',
  REVIEW_GUIDE_PERCENTAGE: 'userlogs/review_guide_progress',
  SAVENOTES: 'practiceans/savenotes',
  SAVESELFPACED_USER_SCHEDULE: 'self_paced/SaveSelfpaced_User_Schedule',
  SAVE_SELF_SCHEDULE: 'self_paced/save_self_schedule',
  SAVE_SELF_SCHEDULE_WELCOMEFLOW: 'authy/save_self_schedule',
  SAVE_USER_ACCESS_DURATION: 'authy/save_access_duration',
  SCHEDULETIME_BY_USER: 'scheduletime/by_user',
  SCHEDULE_DOMAIN: 'disscussion/get_dashboard_domain',
  SELFPACED_SCHEDULES: 'points/selfpaced_schedules',
  SET_NOTIFICATION_SETTING: 'notification/save_setting',
  SITE_URL: window.location.origin,
  STUDENT_REPORT: 'testlogs/studentreport',
  SUPPORT_EMAIL: 'support@destcert.com',
  SURVEY_ACTIVITY: 'acronyms/take_survey',
  SelfPaced_video_Data: 'self_paced/update_section_video_view',
  TEST_LOGS_ADD: 'testlogs/add',
  UPDATE_EXAMDATE: 'self_paced/update_examdate',
  UPDATE_ONBORDING: 'authy/update_onbording',
  UPDATE_OPT: 'enroll/update',
  UPDATE_SHOW_RESOURCES: 'self_paced/update_user_resource',
  UPDATE_TWO_FACT_TAB: 'authy/update_two_fact_tab',
  UPLOAD_FILE: 'chatting/upload_file',
  UPLOAD_LARGE_FILE: 'chatting/upload_largefile',
  UPLOAD_LARGE_FILE_COMPLE: 'chatting/upload_largefile_complete',
  URL_SHORT: 'authy/shortvideo',
  USER: 'users/',
  USERUPDATE_ID: 'users/',
  USER_ADD: 'users/add',
  USER_FILTER: 'users/filter',
  USER_LOGS_ALL_DROPDOWN: 'userlogs/alldropdown',
  USER_UPDATE_BY_EMAIL: 'authy/updateuserbyemail',
  UserPoolId:
    // 'us-east-2_Fch3dpzG3',
    '53616c7465645f5fa3d171fdb0710a0990c3ca9095f3216b97be59a13853d5c39758fc9f064e05754597d5c27076c567',
  VERSIONS_GETUSER_BYEMAIL: 'authy/getUserByEmail',
  VERSIONS_GETUSER_BYID: 'authy/getUserById',
  VIDEO_BY_COURCE: 'video/by_cource',
  VIDEO_BY_COURCE_MEMBERSHIP: 'video/by_cource_membership',
  VIDEO_BY_ID: 'v2/video/by_id',
  VIDEO_NOTEBY_USER: 'video/noteby_user',
  VIDEO_NOTE_ADD: 'video/noteadd',
  VIDEO_SEARCH: 'video/search',
  VIDEO_UPDATE: 'video/update_view',
  apiUrl: 'http://js.stripe.com/v3',
  baseurl,
  pingurl: isUsingVercel
    ? baseurl + 'healthcheck/is-app-ok'
    : './assets/gif.PNG',
  production: false,
};
